import React, { useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSingleDataFromDatabase } from '../utils/api'
import BarChat from '../components/dashboard/BarChat'
import PolarChart from '../components/dashboard/PolarChart'
import DashboardOverview from '../components/dashboard/DashboardOverview'
import DashboardSection from '../components/dashboard/DashboardSection'

const Dashboard = () => {
    const navigate = useNavigate();
    const whouseid = localStorage.getItem('whousedata');
    const [warehouse, setWarehouse] = useState([]);

    useEffect(() => {
        if (whouseid) {
            navigate('/dashboard');
            getDashboardSingleEmpData(whouseid);
        }
        else {
            navigate("/");
        }
    }, [whouseid]);

    const getDashboardSingleEmpData = (whouseid) => {
        getSingleDataFromDatabase(`warehouse-get-single-data/${whouseid}`).then((res) => {
            if (res.data.status === 1) {
                setWarehouse(...[res.data.whousedata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    let today = new Date();
                    let curHr = today.getHours();
                    if (curHr < 12) {
                        return (
                            <h4 className="dashboardname">Good Morning, <span>{warehouse?.whouse_name}</span></h4>
                            
                        )
                    }
                    else if(curHr < 17){
                        return (
                            <h4 className="dashboardname">Good Afternoon, <span>{warehouse?.whouse_name}</span></h4>
                        )
                    }
                    else {
                        return (
                            <h4 className="dashboardname">Good Evening, <span>{warehouse?.whouse_name}</span></h4>
                        )
                    }
                })()}

                {/* Leave Balance */}
                <div className="row">
                    <div className="col-12 col-lg-12 col-sm-6">
                        <DashboardSection />
                    </div>
                    <div className="col-12 col-lg-12 col-sm-6">
                        <DashboardOverview />
                    </div>
                </div>
                {/* End */}
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
