import axios from "axios";

const BASE_URL = "https://satkartar.one/";
//const BASE_URL = "http://localhost/satkartarcrmapi/";

export const loginAPI = async (url, params) => {
    try {
        const sendmobileotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: { mobile: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendmobileotp;
    }
    catch (err) {
        return err;
    }
}

export const verifyMobileOTPAPI = async (url, params) => {
    try {
        const matchedmobileotp = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return matchedmobileotp;
    }
    catch (err) {
        return err;
    }
}

export const reSendMobileOTP = async (url, params) => {
    try {
        const resendOTP = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { mobile: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return resendOTP;
    }
    catch (err) {
        return err;
    }
}

export const addDataAPI = async (url, params) => {
    try {
        const addHr = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return addHr;
    }
    catch (err) {
        return err;
    }
}
export const fetchAllDataAPI = async (url, params) => {
    try {
        const order = await axios({
            method: "get",
            url: BASE_URL + url,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return order;
    }
    catch (err) {
        return err;
    }
}

export const activeStatus = async (url, params) => {
    try {
        const activeStatus = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return activeStatus;
    }
    catch (err) {
        return err;
    }
}
export const deactiveStatus = async (url, params) => {
    try {
        const activeStatus = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return activeStatus;
    }
    catch (err) {
        return err;
    }
}

export const removeDataFromList = async (url, params) => {
    try {
        const activeStatus = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return activeStatus;
    }
    catch (err) {
        return err;
    }
}

export const editDataFromList = async (url, params) => {
    try {
        const editData = await axios({
            method: "GET",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return editData;
    }
    catch (err) {
        return err;
    }
}
export const updateDataAPI = async (url, params) => {
    try {
        const updateData = await axios({
            method: "post",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return updateData;
    }
    catch (err) {
        return err;
    }
}
export const addFormData = async (url, params) => {
    try {
        const addData = await axios({
            method: "POST",
            url: BASE_URL + url,
            mimeType: "multipart/form-data",
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return addData;
    }
    catch (err) {
        return err;
    }
}
export const getSingleDataFromDatabase = async (url, params) => {
    try {
        const editData = await axios({
            method: "GET",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return editData;
    }
    catch (err) {
        return err;
    }
}
export const userUploadProfilePicAPI = async (url, params) => {
    try {
        const profilePic = await axios({
            method: "post",
            url: BASE_URL + url,
            mimeType: "multipart/form-data",
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return profilePic;
    }
    catch (err) {
        return err;
    }
}
export const fetchAllDataByIDAPI = async (url, params) => {
    try {
        const order = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return order;
    }
    catch (err) {
        return err;
    }
}
export const getDataAPIToPincode = async (url, params) => {
    try {
        const sendmobileotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: { pincode: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendmobileotp;
    }
    catch (err) {
        return err;
    }
}
export const fetchAllDashboardDataAPI = async (url) => {
    try {
        const order = await axios({
            method: "GET",
            url: BASE_URL + url,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return order;
    }
    catch (err) {
        return err;
    }
}
export const filterByParams = async (url, params) => {
    try {
        const addData = await axios({
            method: "POST",
            url: BASE_URL + url,
            mimeType: "multipart/form-data",
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return addData;
    }
    catch (err) {
        return err;
    }
}