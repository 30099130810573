import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaProductHunt, FaRegHandPointRight } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { editDataFromList, fetchAllDataAPI, updateDataAPI } from '../utils/api';
import Button from 'react-bootstrap/Button';


const initialState = {
    pcat_id: '',
    product_name: '',
    product_price: '',
    product_buy_price: '',
    product_sku_number: '',
    product_tax_rate: '',
    product_width: '',
    product_weight: '',
    product_length: '',
    product_height: '',
    product_sell_price: '',
    product_disc_rate: '',
    product_bredth: '',
    product_total_qty: '',
    product_bar_code: '',
    product_mnf_date: '',
    product_exp_date: '',
    product_form: '',
    product_supplier: '',
    product_shipping_charge: '',
    product_desc: '',
    product_hsn_code: '',
    product_uom: '',
    product_igst: '',
}
const EditProduct = () => {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [values, setProductValue] = useState(initialState);
    const [category, setCategory] = useState([]);
    const [company, setCompany] = useState([]);
    const { slug } = useParams();

    useEffect(() => {
        getActiveCategory();
        getSingleProduct(slug);
    }, [slug]);

    const getSingleProduct = () => {
        editDataFromList(`warehouse-get-single-product/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setProductValue(...[res.data.product]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    const getActiveCategory = () => {
        fetchAllDataAPI('warehouse-get-active-category-data').then((res) => {
            if (res.data.status === 1) {
                setCategory(...[res.data.category]);
                setCompany(...[res.data.company]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }

    const handleUpdateProduct = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                updateDataAPI(`warehouse-update-product-data/${slug}`, values).then((res) => {
                    if (res.data.status === 1) {
                        navigate('/all-products');
                        toast.success("Product Data has been updated successfully!!");
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Product already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };


    const handleInputs = e => {
        setProductValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="warehouse">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::EDIT PRODUCT</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Edit Product</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12 card cardform marbottom">
                        <div className="card border-top border-0 border-4 border-danger" style={{ background: "#f5f6fa" }}>
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FaProductHunt className="formIcons" />
                                        <h5 className="mb-0 text-danger">Edit Product</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleUpdateProduct} method="post">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Select Company</Form.Label>
                                            <Form.Select required
                                                placeholder="Company Name"
                                                name="pcomp_id"
                                                onChange={handleInputs}>
                                                <option value={values.pcomp_id}>{values.company_name}</option>
                                                {company?.map((company, index) => (
                                                    <option key={index} value={company?.company_id}>{company?.company_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a category name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Select Category</Form.Label>
                                            <Form.Select required
                                                placeholder="Category Name"
                                                name="pcat_id"
                                                onChange={handleInputs}>
                                                <option value={values.pcat_id}>{values.cat_name}</option>
                                                {category?.map((category, index) => (
                                                    <option key={index} value={category?.cat_id}>{category?.cat_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a category name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                                            <Form.Label>Product Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Name"
                                                name="product_name"
                                                onChange={handleInputs}
                                                defaultValue={values.product_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a category name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                                            <Form.Label>Product Price</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Price"
                                                name="product_price"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_price}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product Price
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                                            <Form.Label>Product Buy Price</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Buy Price"
                                                name="product_buy_price"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_buy_price}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product Price
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                                            <Form.Label>Product Sell Price</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Sell Price"
                                                name="product_sell_price"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_sell_price}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Product Sell Price
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom06">
                                            <Form.Label>Product SKU Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product SKU Number"
                                                name="product_sku_number"
                                                onChange={handleInputs}
                                                defaultValue={values.product_sku_number}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product SKU number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom07">
                                            <Form.Label>Product Tax Rate</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Tax Rate"
                                                name="product_tax_rate"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_tax_rate}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product tax rate
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom08">
                                            <Form.Label>Product Discount Rate</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Discount Rate"
                                                name="product_disc_rate"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_disc_rate}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product tax rate
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom14">
                                            <Form.Label>Product Total QTY</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Total QTY"
                                                name="product_total_qty"
                                                pattern="[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_total_qty}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product QTY
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom15">
                                            <Form.Label>Product Manufacturing Date</Form.Label>
                                            <Form.Control
                                                required
                                                type="date"
                                                placeholder="Product Manufacturing Date"
                                                name="product_mnf_date"
                                                onChange={handleInputs}
                                                defaultValue={values.product_mnf_date}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product QTY
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom16">
                                            <Form.Label>Product Expiry Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Product Expiry Date"
                                                name="product_exp_date"
                                                onChange={handleInputs}
                                                defaultValue={values.product_exp_date}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a expiry date
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom18">
                                            <Form.Label>Select UOM</Form.Label>
                                            <Form.Select required
                                                placeholder="Select UOM"
                                                name="product_uom"
                                                onChange={handleInputs}>
                                                <option value={values.product_uom}>{values.product_uom}</option>
                                                <option value="AVERAGE">AVERAGE</option>
                                                <option value="BAG">BAG</option>
                                                <option value="BOTTLE">BOTTLE</option>
                                                <option value="BOX">BOX</option>
                                                <option value="CAN">CAN</option>
                                                <option value="CARTON">CARTON</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a category name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom19">
                                            <Form.Label>Select Product Form</Form.Label>
                                            <Form.Select required
                                                placeholder="Category Name"
                                                name="product_form"
                                                onChange={handleInputs}>
                                                <option value={values.product_form}>{values.product_form}</option>
                                                <option value="TABLETS">TABLETS</option>
                                                <option value="POWDER">POWDER</option>
                                                <option value="SUSPENSION">SUSPENSION</option>
                                                <option value="SYRUP">SYRUP</option>
                                                <option value="CAPSULES">CAPSULES</option>
                                                <option value="DROPS">DROPS</option>
                                                <option value="LIQUID">LIQUID</option>
                                                <option value="OIL">OIL</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a category name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom20">
                                            <Form.Label>Product Supplier Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Supplier Name"
                                                name="product_supplier"
                                                onChange={handleInputs}
                                                defaultValue={values.product_supplier}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product supplier name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom21">
                                            <Form.Label>Product Shipping Charge</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Shipping Charge"
                                                name="product_shipping_charge"
                                                onChange={handleInputs}
                                                defaultValue={values.product_shipping_charge}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product shipping charge
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom22">
                                            <Form.Label>Product HSN Code</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product HSN Code"
                                                name="product_hsn_code"
                                                onChange={handleInputs}
                                                pattern="[0-9]*"
                                                defaultValue={values.product_hsn_code}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product HSN Code
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom23">
                                            <Form.Label>IGST</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter the IGST"
                                                name="product_igst"
                                                onChange={handleInputs}
                                                defaultValue={values.product_igst}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid IGST
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <hr />
                                    <Row className="mb-4">
                                        <Form.Group as={Col} md="3" controlId="validationCustom13">
                                            <Form.Label>Product Weight(kg)</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Weight(kg)"
                                                name="product_weight"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_weight}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight />  Please provide a product Weight(gm)
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom12">
                                            <Form.Label>Product Length(CM)</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Length(CM)"
                                                name="product_length"
                                                pattern="[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_length}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product Length
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom11">
                                            <Form.Label>Product Bredth(CM)</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Bredth(CM)"
                                                name="product_bredth"
                                                pattern="[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_bredth}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a p product Bredth
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom09">
                                            <Form.Label>Product Height(CM)</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Product Height(CM)"
                                                name="product_height"
                                                pattern="[0-9]*"
                                                onChange={handleInputs}
                                                defaultValue={values.product_height}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product Height
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Form.Group as={Col} md="12" controlId="validationCustom24">
                                            <Form.Label>Product Description</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="text"
                                                placeholder="Product Desc"
                                                name="product_desc"
                                                onChange={handleInputs}
                                                defaultValue={values.product_desc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a product description
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit" className="btn btn-md btn-success d-flex justify-content-center mx-auto">Submit</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditProduct