import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.webp"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineDashboard, AiOutlineLogout, AiOutlineMenu, AiOutlineSetting, AiFillCloseCircle, AiFillProfile } from "react-icons/ai";
import { getSingleDataFromDatabase } from '../utils/api';
import { BASEURLS } from '../utils/BaseUrl';
import { FaProductHunt } from 'react-icons/fa';
import { BiSolidCategory } from "react-icons/bi";
import { FcBusinessman } from "react-icons/fc";

const Sidebar = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const whouseid = localStorage.getItem('whousedata');
    const [warehouse, setWarehouse] = useState([]);

    const openMobileMenu = () => {
        if (mobileMenu) {
            setMobileMenu(false)
        }
        else {
            setMobileMenu(true)
        }
    }

    useEffect(() => {
        getSingleMisData(whouseid);
    }, [whouseid]);

    const getSingleMisData = (whouseid) => {
        getSingleDataFromDatabase(`warehouse-get-single-data/${whouseid}`).then((res) => {
            if (res.data.status === 1) {
                setWarehouse(...[res.data.whousedata]);
            }
        })
    }
    const navigate = useNavigate();
    function logout() {
        localStorage.clear();
        navigate("/");
    }
    return (
        <div className="wrapper">
            <div className="topbarSection">
                <div className="topbar">
                    <div className="mobile-toggle-menu">
                        <Link to="/dashboard">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <nav className="navbar navbar-expand">
                        <div className="user-box dropdown desktopDisplay">
                            <button className="d-flex align-items-center nav-link" role="button">
                                <div className="username">
                                    <div className="userimg">
                                        {(() => {
                                            if (warehouse) {
                                                return (
                                                    <img className="rounded-circle" src={BASEURLS(warehouse?.whouse_foto)} alt={warehouse?.whouse_foto} />
                                                )
                                            }
                                            else {
                                                return (
                                                    <img className="rounded-circle" src={BASEURLS("user.png")} alt="user.png" />
                                                )
                                            }
                                        })()}

                                    </div>
                                    <div className="user-info ps-3">
                                        <p className="user-name mb-0 dropdown-toggle" data-bs-toggle="dropdown">{warehouse?.whouse_name}</p>
                                        <p className="designattion mb-0">Warehouse</p>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="/my-profile"><AiOutlineSetting />Settings</Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" onClick={logout} to="javascript:void(0)"><AiOutlineLogout />LogOut</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="mobileDisplay">
                            <button onClick={openMobileMenu}>
                                {mobileMenu ?
                                    (
                                        <AiFillCloseCircle className="mobileMenuIcones" />
                                    )
                                    :
                                    (
                                        <AiOutlineMenu className="mobileMenuIcones" />
                                    )
                                }
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="sidebarWrapper desktopDisplay">
                <div className="simplebar-mask">
                    <div className="simplebar-offset">
                        <div className="simplebar-content-wrapper">
                            <div className="simplebar-content mm-active">
                                <ul className="metismenu mm-show" id="menu">
                                    <li>
                                        <NavLink activeclassname="active" to="/dashboard">
                                            <AiOutlineDashboard />
                                            <span>Dashboard</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/category">
                                        <BiSolidCategory />
                                            <span>Category</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-products">
                                            <FaProductHunt />
                                            <span>All Product</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-cms-orders">
                                            <AiOutlineDashboard />
                                            <span>CMS Orders</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/all-orders">
                                            <AiOutlineDashboard />
                                            <span>All Orders</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/dealer">
                                        <FcBusinessman />
                                            <span>Dealer</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeclassname="active" to="/my-profile">
                                            <AiFillProfile />
                                            <span>My Profile</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={logout} to="javascript:void(0)">
                                            <AiOutlineLogout />
                                            <span>Log Out</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${mobileMenu ? "" : "mobileMenuActive"}`}>
                <div className="mobilesidebarWrapper mobileDisplay">
                    <div className="simplebar-mask">
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                <div className="simplebar-content mm-active">
                                    <ul className="metismenu mm-show" id="menu">
                                        <li>
                                            <NavLink activeclassname="active" to="/dashboard">
                                                <AiOutlineDashboard />
                                                <span>Dashboard</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeclassname="active" to="/category">
                                            <BiSolidCategory />
                                                <span>All Category</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeclassname="active" to="/all-orders">
                                                <AiOutlineSetting />
                                                <span>Orders</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeclassname="active" to="/all-products">
                                                <FaProductHunt />
                                                <span>All Product</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={logout} to="javascript:void(0)">
                                                <AiOutlineLogout />
                                                <span>LogOut</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
